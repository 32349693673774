.health-card-container {
  width: 380px;
  height: 256px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.health-card-container01 {
  width: 136px;
  height: 46px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 4px;
  padding-bottom: 4px;
  justify-content: center;
  background-color: var(--dl-color-primaryelements-huh);
}
.health-card-text {
  color: var(--dl-color-primarybackground-700);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.health-card-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 4px;
  background-color: var(--dl-color-primarybackground-700);
}
.health-card-container03 {
  width: 246px;
  height: 210px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.health-card-container04 {
  width: 244px;
  height: 94px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  flex-direction: column;
  border-right-width: 2px;
  border-bottom-width: 2px;
}
.health-card-container05 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.health-card-container06 {
  flex: 0 0 auto;
  width: 47px;
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.health-card-text01 {
  color: var(--dl-color-primaryelements-huh);
  font-size: 20px;
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.health-card-textinput {
  color: var(--dl-color-primaryelements-huh);
  width: 83px;
  height: 100%;
  padding: 4px;
  font-size: 20px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.health-card-container07 {
  flex: 0 0 auto;
  width: 30px;
  height: 100%;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  padding-bottom: 4px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.health-card-text02 {
  color: var(--dl-color-primaryelements-huh);
  font-size: 10px;
  font-style: normal;
  text-align: center;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.health-card-container08 {
  flex: 0 0 auto;
  width: 81px;
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.health-card-text03 {
  color: var(--dl-color-primaryelements-huh);
  font-size: 20px;
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.health-card-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primarybackground-700);
  border-width: 2px;
  justify-content: center;
  background-color: var(--dl-color-primaryelements-huh);
}
.health-card-text04 {
  color: var(--dl-color-primarybackground-700);
  font-size: 20px;
  text-transform: uppercase;
}
.health-card-container10 {
  flex: 0 0 auto;
  width: 244px;
  display: flex;
  align-items: flex-start;
}
.health-card-container11 {
  width: var(--dl-size-size-xlarge);
  height: 114px;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-right-width: 2px;
}
.health-card-text05 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  align-self: flex-start;
  text-align: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  border-bottom-width: 2px;
}
.health-card-container12 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.health-card-text08 {
  color: var(--dl-color-primaryelements-huh);
  padding-right: 4px;
}
.health-card-select {
  width: 170px;
}
.health-card-container13 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 1px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.health-card-text09 {
  color: var(--dl-color-primaryelements-huh);
  padding-right: 4px;
}
.health-card-select1 {
  width: 170px;
}
.health-card-container14 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.health-card-text10 {
  color: var(--dl-color-primaryelements-huh);
  padding-right: 4px;
}
.health-card-select2 {
  width: 170px;
}
.health-card-container15 {
  flex: 0 0 auto;
  width: 52px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-style: solid;
  border-width: 0px;
  flex-direction: column;
  border-right-width: 2px;
}
.health-card-text11 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  align-self: flex-start;
  text-align: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  border-bottom-width: 2px;
}
.health-card-container16 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.health-card-checkbox {
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
}
.health-card-container17 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.health-card-checkbox1 {
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
}
.health-card-container18 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.health-card-checkbox2 {
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
}
.health-card-container19 {
  flex: 0 0 auto;
  width: 127px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.health-card-text14 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  align-self: flex-start;
  text-align: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 0px;
  border-bottom-width: 2px;
}
.health-card-textinput1 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  height: 25px;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
}
.health-card-textinput2 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  height: 25px;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
}
.health-card-textinput3 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  height: 25px;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
}
.health-card-textinput4 {
  color: var(--dl-color-primaryelements-huh);
  width: 100%;
  height: 25px;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
}
.health-card-root-class-name {
  margin: var(--dl-space-space-halfunit);
}
