.login-box-container {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.login-box-container1 {
  width: 345px;
  height: 179px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 4px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.login-box-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 33%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
}
.login-box-text {
  color: var(--dl-color-primaryelements-huh);
  align-self: center;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}
.login-box-textinput {
  color: var(--dl-color-primaryelements-huh);
  border-color: var(--dl-color-primaryelements-huh);
  border-style: solid;
  border-width: 2px;
  border-radius: 0px;
  background-color: transparent;
}
.login-box-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 33%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
}
.login-box-text1 {
  color: var(--dl-color-primaryelements-huh);
  align-self: center;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}
.login-box-textinput1 {
  color: var(--dl-color-primaryelements-huh);
  border-color: var(--dl-color-primaryelements-huh);
  border-style: solid;
  border-width: 2px;
  border-radius: 0px;
  background-color: transparent;
}
.login-box-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box-button {
  color: var(--dl-color-primaryelements-huh);
  font-style: normal;
  font-weight: 700;
  border-width: 0px;
  border-radius: 0px;
  letter-spacing: 1px;
  background-color: var(--dl-color-primaryelements-backgroundprimary);
}

