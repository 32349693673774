.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primarybackground-darkbackground);
}
.login-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container2 {
  flex: 1;
  width: 100%;
  height: 33%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.login-text {
  color: var(--dl-color-primaryelements-huh);
  font-size: 4em;
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 300;
}
.login-container3 {
  flex: 1;
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-container4 {
  flex: 2;
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}
