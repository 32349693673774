.character-info-card1-container {
  width: 380px;
  height: var(--dl-size-size-large);
  margin: var(--dl-space-space-halfunit);
  display: flex;
  padding: 0px;
  position: relative;
  align-items: stretch;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 3px;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-primarybackground-700);
}
.character-info-card1-container01 {
  width: 100%;
  height: 179px;
  display: flex;
  align-items: flex-start;
}
.character-info-card1-image-container {
  flex: 0 0 auto;
  width: 108px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primaryelements-huh);
  border-style: solid;
  border-width: 0px;
  border-right-width: 2px;
}
.character-info-card1-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.character-info-card1-stats-container {
  flex: 0 0 auto;
  width: 267px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.character-info-card1-container02 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.character-info-card1-container03 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
}
.character-info-card1-text {
  color: var(--dl-color-primaryelements-huh);
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.character-info-card1-textinput {
  color: var(--dl-color-primaryelements-huh);
  width: 66px;
  height: 100%;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
}
.character-info-card1-container04 {
  flex: 0 0 auto;
  width: 89px;
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
}
.character-info-card1-text1 {
  color: var(--dl-color-primaryelements-huh);
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.character-info-card1-input {
  color: var(--dl-color-primaryelements-huh);
  width: 63px;
  height: 100%;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.character-info-card1-container05 {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: flex-start;
}
.character-info-card1-container06 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.character-info-card1-container07 {
  flex: 0 0 auto;
  width: 85px;
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.character-info-card1-text2 {
  color: var(--dl-color-primaryelements-huh);
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.character-info-card1-textinput1 {
  color: var(--dl-color-primaryelements-huh);
  width: 73px;
  height: 100%;
  padding: 4px;
  text-align: center;
  font-family: Roboto Condensed;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  border-radius: 0px;
  background-color: var(--dl-color-primarybackground-inputbg);
  border-top-width: 2px;
  border-left-width: 0px;
}
.character-info-card1-container08 {
  flex: 0 0 auto;
  width: 38px;
  height: 100%;
  display: flex;
  padding: 4px;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  padding-bottom: 4px;
  justify-content: flex-start;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.character-info-card1-text3 {
  color: var(--dl-color-primaryelements-huh);
  font-size: 10px;
  font-style: normal;
  text-align: center;
  font-family: Roboto Condensed;
  font-weight: 400;
}
.character-info-card1-container09 {
  flex: 0 0 auto;
  width: 73px;
  height: 100%;
  display: flex;
  padding: 4px;
  align-items: center;
  border-color: var(--dl-color-primaryelements-huh);
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.character-info-card1-text4 {
  color: var(--dl-color-primaryelements-huh);
  font-style: normal;
  font-family: Roboto Condensed;
  font-weight: 400;
}

